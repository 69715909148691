<template>
  <div class="admin-container">
    <template v-if="user">
      <v-row>
        <v-col cols="12" md="3"
          ><v-text-field v-model="user.first_name" label="Jméno"></v-text-field
        ></v-col>
        <v-col cols="12" md="3"
          ><v-text-field
            v-model="user.last_name"
            label="Příjmení"
          ></v-text-field
        ></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3"
          ><v-select
            :items="roles"
            v-model="user.role"
            label="Typ uživatele"
            item-text="name_loc"
            item-value="name"
            disabled
          ></v-select
        ></v-col>
        <v-col cols="12" md="3"
          ><v-text-field
            v-model="user.phone_number"
            label="Telefonní číslo"
          ></v-text-field
        ></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3"
          ><v-text-field
            v-model="user.email"
            disabled
            label="E-mail"
          ></v-text-field
        ></v-col>
      </v-row>
      <v-row class="mt-8">
        <v-col class="text-right">
          <v-btn text small @click="$router.push({ name: 'Password' })"
            >změna hesla</v-btn
          >
          <v-btn
            small
            tile
            color="secondary"
            class="mr-4"
            @click="$router.push({ name: 'Dashboard' })"
            >Zrušit</v-btn
          >
          <v-btn small tile color="primary" @click="updateUser">Uložit</v-btn>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import bus from "../plugins/bus";
import users from "../api/userService";
import authHandler from "../api/authHandler";
import userService from "../api/userService";

export default {
  data() {
    return {
      user: null,
      roles: [],
    };
  },
  async created() {
    await this.getRoles();
    await this.getUser();
  },
  methods: {
    async getUser() {
      bus.$emit("processing");
      if (!this.$store.state.user) {
        try {
          await this.$store.dispatch("getCurrentUser");
          console.debug(this.$store.state.user);
          this.user = this.$store.state.user;
        } catch (error) {
          this.$router.push({ name: "Login" });
          bus.$emit("processing", false);
        }
      } else {
        console.debug(this.$store.state.user);
        this.user = this.$store.state.user;
        bus.$emit("processing", false);
      }
    },
    async getRoles() {
      try {
        this.roles = await userService.getRoles();
      } catch (error) {
        throw error;
      }
    },
    async deleteUser() {
      try {
        bus.$emit("processing");
        await userService.deleteUser(this.$route.params.userId);
        bus.$emit("processing", false);
        this.$router.push({ name: "Users" });
      } catch (error) {
        console.error(error);
        bus.$emit("processing", false);
        if (!authHandler(error)) this.$router.push({ name: "Login" });
        if (error.response && error.response.data) {
          bus.$emit("snackbar", {
            text: error.response.data.message,
            color: "error",
          });
        }
      }
    },
    async updateUser() {
      try {
        bus.$emit("processing");
        const response = await users.updateSelf(this.user);
        await this.$store.dispatch("getCurrentUser");
        console.debug(this.$store.state.user);
        this.user = this.$store.state.user;
        console.debug("Response", response);
        bus.$emit("processing", false);
        bus.$emit("snackbar", {
          text: "Uloženo",
          color: "success",
        });
      } catch (error) {
        console.error(error);
        bus.$emit("processing", false);
      }
    },
  },
  watch: {},
};
</script>

<style></style>
